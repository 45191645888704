import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import { StaticImage } from "gatsby-plugin-image";
import Title from "../shared/titles";
import Button from "../shared/buttons";
import { useIntl } from "gatsby-plugin-react-intl";
import CustomDownloadButton from "../shared/CustomDownloadButton";
import { whiteDownloadBtn } from "../../utils/CustomDownloadBtnStyles";

const Container = styled.div`
  display: grid;
  grid-area: 1/1;
  place-items: center;
  height: 360px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  //needs background because image background is transparent
  background: #3571ce;

  .background-image {
    //height is 360px because this components height is 360px and it doesn't change while width does
    height: 360px;
    width: 100%;
    z-index: 0;
    grid-area: 1/1;
    @media (max-width: 460px) {
      height: 500px;
    }
  }

  @media (max-width: 460px) {
    height: 500px;
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 360px;
  gap: 42px;
  z-index: 1;
  @media (max-width: 960px) {
    height: max-content;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
function Learn() {
  const intl = useIntl();
  const lang = intl.formatMessage({
    id: "Lang",
  });
  return (
    <Container>
      <StaticImage
        src="../../images/sharedImages/background_img.png"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF", "PNG"]}
        alt="Ready Background Image"
        className="background-image"
      />
      <PositionContainer>
        <ContentHolder>
          <Row>
            <Title special={true}>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "FeaturesCTATitle",
                  }),
                }}
              ></div>
            </Title>
          </Row>
          <Row>
            <CustomDownloadButton
              title={intl.formatMessage({ id: "FeaturesCTATryPro" })}
              navigation={`https://pdfpro.com/?utm_source=pdfpro.${lang}&utm_medium=referral`}
              tracker="tracker"
              styles={whiteDownloadBtn}
              target="_blank"
            />
            <Button
              title={intl.formatMessage({
                id: "FeaturesCTAComparer",
              })}
              navigation={`/${intl.formatMessage({
                id: "UrlCompare",
              })}`}
              type="white-empty"
              width="216px"
            />
          </Row>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Learn;
