import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import MainTitle from "../shared/heroTitles";
import Title from "../shared/titles";
import Paragraph from "../shared/paragraphs";
import List from "../shared/customLists";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
// moras jos svugde dodati u TextBox i /shared/lists
//dovrsi media queries da bude responsive page
// napravi last element more reusable

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;

  .img {
    @media (max-width: 1260px) {
      width: 488px;
      height: 488px;
    }
    @media (max-width: 1100px) {
      width: 400px;
      height: 400px;
    }
    @media (max-width: 960px) {
      width: 588px;
      height: 588px;
    }

    @media (max-width: 600px) {
      width: 488px;
      height: 488px;
    }
    @media (max-width: 500px) {
      width: 388px;
      height: 388px;
    }
    @media (max-width: 400px) {
      width: 320px;
      height: 320px;
    }
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  gap: 80px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 40px;
  }
`;

const ParagraphUnderTitle = styled.p`
  width: 500px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #111111;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ImgHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;

  @media (max-width: 960px) {
    order: 1;
    padding-left: 0;
    padding-right: 0;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 486px;
  @media (max-width: 960px) {
    order: 2;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  text-indent: 40px;
  width: 100%;
  gap: 8px;
  ${(props) => (props.bullet ? `list-style-type: ${props.bullet};` : ``)};
  ${(props) => (props.padding ? `padding-left: ${props.padding};` : ``)};
  list-style-type: "-----" !important;
`;

function Image1() {
  const intl = useIntl();
  const imageNameFr = "freature1-fr.png";
  const imageNameEs = "freature1-es.png";
  return (
    <ImgHolder>
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "fr" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameFr}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "es" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameEs}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
    </ImgHolder>
  );
}

function Image2() {
  const intl = useIntl();
  const imageNameFr = "freature2-fr.png";
  const imageNameEs = "freature2-es.png";
  return (
    <ImgHolder>
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "fr" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameFr}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "es" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameEs}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
    </ImgHolder>
  );
}

function Image3() {
  const intl = useIntl();
  const imageNameFr = "freature3-fr.png";
  const imageNameEs = "freature3-es.png";
  return (
    <ImgHolder>
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "fr" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameFr}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "es" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameEs}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
    </ImgHolder>
  );
}

function Image4() {
  const intl = useIntl();
  const imageNameFr = "freature4-fr.png";
  const imageNameEs = "freature4-es.png";
  return (
    <ImgHolder>
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "fr" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameFr}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "es" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameEs}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
    </ImgHolder>
  );
}

function Hero() {
  const intl = useIntl();

  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <Column>
            <MainTitle features={true}>
              {intl.formatMessage({
                id: "FeaturesFeaturesTitle",
              })}
            </MainTitle>
            <ParagraphUnderTitle
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "FeaturesFeaturesUnderTitle",
                }),
              }}
            ></ParagraphUnderTitle>
          </Column>
          <Row>
            <Image1 />
            <TextBox>
              <Title margin="0px 0px 32px 0px" width="485px">
                {intl.formatMessage({
                  id: "FeaturesSectionOneTitle",
                })}
              </Title>
              <Paragraph>
                {intl.formatMessage({
                  id: "FeaturesSectionOneParagraph",
                })}
              </Paragraph>
              <Ul>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionOnePointOne",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionOnePointTwo",
                  })}
                </List>
                <Ul bullet="- " padding="20%">
                  <List bullet="- ">
                    {intl.formatMessage({
                      id: "FeaturesSectionOnePointTwoSubOne",
                    })}{" "}
                  </List>
                  <List bullet="- ">
                    {intl.formatMessage({
                      id: "FeaturesSectionOnePointTwoSubTwo",
                    })}
                  </List>
                  <List bullet="- ">
                    {intl.formatMessage({
                      id: "FeaturesSectionOnePointTwoSubThree",
                    })}
                  </List>
                  <List bullet="- ">
                    {intl.formatMessage({
                      id: "FeaturesSectionOnePointTwoSubFour",
                    })}
                  </List>
                </Ul>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionOnePointThree",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionOnePointFour",
                  })}
                </List>
              </Ul>
            </TextBox>
          </Row>
          <Row>
            <TextBox>
              <Title margin="0px 0px 32px 0px" width="485px">
                {intl.formatMessage({
                  id: "FeaturesSectionTwoTitle",
                })}
              </Title>
              <Paragraph>
                {intl.formatMessage({
                  id: "FeaturesSectionTwoParagraph",
                })}
              </Paragraph>
              <Ul>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionTwoPointOne",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionTwoPointTwo",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionTwoPointThree",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionTwoPointFour",
                  })}
                </List>
              </Ul>
            </TextBox>
            <ImgHolder>
              <Image2 />
            </ImgHolder>
          </Row>
          <Row>
            <ImgHolder>
              <Image3 />
            </ImgHolder>
            <TextBox>
              <Title margin="0px 0px 32px 0px" width="485px">
                {intl.formatMessage({
                  id: "FeaturesSectionThreeTitle",
                })}
              </Title>
              <Paragraph>
                {intl.formatMessage({
                  id: "FeaturesSectionTwoParagraph",
                })}
              </Paragraph>
              <Ul>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionThreePointOne",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionThreePointTwo",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionThreePointThree",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionThreePointFour",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionThreePointFive",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionThreePointSix",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionThreePointSeven",
                  })}
                </List>
              </Ul>
            </TextBox>
          </Row>
          <Row>
            <TextBox>
              <Title margin="0px 0px 32px 0px" width="485px">
                {intl.formatMessage({
                  id: "FeaturesSectionFourTitle",
                })}
              </Title>
              <Paragraph>
                {intl.formatMessage({
                  id: "FeaturesSectionFourParagraph",
                })}
              </Paragraph>
              <Ul>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionFourPointOne",
                  })}
                </List>
                <Ul bullet="- " padding="20%">
                  <List bullet="- ">
                    {intl.formatMessage({
                      id: "FeaturesSectionFourPointOneSubOne",
                    })}{" "}
                  </List>
                  <List bullet="- ">
                    {intl.formatMessage({
                      id: "FeaturesSectionFourPointOneSubTwo",
                    })}
                  </List>
                  <List bullet="- ">
                    {intl.formatMessage({
                      id: "FeaturesSectionFourPointOneSubThree",
                    })}
                  </List>
                  <List bullet="- ">
                    {intl.formatMessage({
                      id: "FeaturesSectionFourPointOneSubFour",
                    })}
                  </List>
                </Ul>

                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionFourPointTwo",
                  })}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionFourPointThree",
                  })}{" "}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionFourPointFour",
                  })}{" "}
                </List>
                <List fontSize="16px" list="outside">
                  {intl.formatMessage({
                    id: "FeaturesSectionFourPointFive",
                  })}{" "}
                </List>
              </Ul>
            </TextBox>
            <ImgHolder>
              <Image4 />
            </ImgHolder>
          </Row>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Hero;
