import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/featuresPage/hero";
import Other from "../components/featuresPage/other";
import Upgrade from "../components/featuresPage/upgrade";
import Learn from "../components/featuresPage/learn";
import Layout from "../components/shared/layout";
import { useIntl } from "gatsby-plugin-react-intl";

function Features() {
  const intl = useIntl();

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({
          id: "FeaturesPageTitle",
        })}
        description={intl.formatMessage({
          id: "FeaturesPageDescription",
        })}
      />
      <Hero />
      <Other />
      <Upgrade />
      <Learn />
    </Layout>
  );
}

export default Features;
