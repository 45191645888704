import React from "react";
import styled from "styled-components";
import Title from "../shared/titles";
import Paragraph from "../shared/paragraphs";
import List from "../shared/customLists";
import PositionContainer from "../shared/container";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 90px 0px;

  .img1 {
    @media (max-width: 1260px) {
      height: 309px;
      width: 511px;
    }
    @media (max-width: 1100px) {
      height: 279px;
      width: 430px;
    }

    @media (max-width: 960px) {
      height: 409px;
      width: 611px;
    }
    @media (max-width: 630px) {
      height: 220px;
      width: 329px;
    }
  }
`;

const ContentHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 485px;

  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  width: 408px;
  padding: 0px;
  margin-left: 16px;
  gap: 24px;

  @media (max-width: 650px) {
    width: 90%;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  li,
  ul {
    width: 100%;
    align-content: flex-start;
    justify-content: flex-start;
  }
`;

const ImgHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10%;

  @media (max-width: 960px) {
    order: 1;
    padding-left: 0;
  }
`;

function ImagePro() {
  const intl = useIntl();
  const imageNameFr = "pro_fr.png";
  const imageNameEs = "pro_es.png";
  return (
    <ImgHolder>
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "fr" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameFr}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
      {intl.formatMessage({
        id: "FeaturesImageSet",
      }) == "es" && (
        <StaticImage
          src={`../../images/featuresPageImages/heroImages/${imageNameEs}`}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF", "PNG"]}
          alt="Create tab in PDF Pro"
          className="img"
        />
      )}
    </ImgHolder>
  );
}

function Upgrade() {
  const intl = useIntl();

  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <Column>
            <TextBox>
              <Title width="485px" margin="0px 0px 32px 0px">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "FeaturesUpgradeTitle",
                    }),
                  }}
                />
              </Title>
              <Paragraph>
                {intl.formatMessage({
                  id: "FeaturesUpgradeParagraph",
                })}
              </Paragraph>
            </TextBox>
            <Group>
              <List fontSize="16px">
                {intl.formatMessage({
                  id: "FeaturesUpgradePointOne",
                })}{" "}
              </List>
              <List fontSize="16px">
                {intl.formatMessage({
                  id: "FeaturesUpgradePointTwo",
                })}{" "}
              </List>
              <Ul bullet="- " padding="20%">
                <List fontSize="16px" bullet="- ">
                  {intl.formatMessage({
                    id: "FeaturesUpgradePointTwoSubOne",
                  })}{" "}
                </List>
                <List fontSize="16px" bullet="- ">
                  {intl.formatMessage({
                    id: "FeaturesUpgradePointTwoSubTwo",
                  })}
                </List>
                <List fontSize="16px" bullet="- ">
                  {intl.formatMessage({
                    id: "FeaturesUpgradePointTwoSubThree",
                  })}
                </List>
              </Ul>
              <List fontSize="16px">
                {intl.formatMessage({
                  id: "FeaturesUpgradePointThree",
                })}{" "}
              </List>
              <List fontSize="16px">
                {intl.formatMessage({
                  id: "FeaturesUpgradePointFour",
                })}{" "}
              </List>

              <List fontSize="16px">
                {intl.formatMessage({
                  id: "FeaturesUpgradePointFive",
                })}{" "}
              </List>
              <List fontSize="16px">
                {intl.formatMessage({
                  id: "FeaturesUpgradePointSix",
                })}{" "}
              </List>
              <List fontSize="16px">
                {intl.formatMessage({
                  id: "FeaturesUpgradePointSeven",
                })}{" "}
              </List>
              <List fontSize="16px">
                {intl.formatMessage({
                  id: "FeaturesUpgradePointEight",
                })}{" "}
              </List>
            </Group>
          </Column>
          <Column>
            <ImagePro />
          </Column>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Upgrade;
