import React, { useContext } from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import Title from "../shared/titles";
import Paragraph from "../shared/paragraphs";
import CustomDownloadButton from "../shared/CustomDownloadButton";
import { useIntl } from "gatsby-plugin-react-intl";
import { blueDownloadBtn } from "../../utils/CustomDownloadBtnStyles";
import { GlobalContext } from "../../Contexts/GlobalContext";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 462px;
  width: 100%;
  background: #fbfbfb;
  border: 1px solid #e1e1e1;

  @media (max-width: 960px) {
    height: max-content;
    padding: 40px 0px;
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 475px) {
    &:nth-child(3) {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

const TextBox = styled.div`
  width: 792px;
  margin-bottom: 40px;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

function Other() {
  const intl = useIntl();
  const { isMac, setMacOpen, setLinkType } = useContext(GlobalContext);

  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <Row>
            <Title margin="0px 0px 32px 0px">
              {intl.formatMessage({
                id: "FeaturesReasonTitle",
              })}
            </Title>
          </Row>
          <Row>
            <TextBox>
              <Paragraph type="center" typeSpecial="left">
                {intl.formatMessage({
                  id: "FeaturesReasonParagraph",
                })}
              </Paragraph>
            </TextBox>
          </Row>
          <Row>
            {!isMac ? (
              <CustomDownloadButton
                title={intl.formatMessage({ id: "ToDownload" })}
                navigation="https://track.pdfpro10.com/product/normal/pdfcreator/trial"
                tracker="tracker"
                styles={blueDownloadBtn}
              />
            ) : (
              <CustomDownloadButton
                title={intl.formatMessage({ id: "ToDownload" })}
                navigation={"/"}
                styles={blueDownloadBtn}
                onClick={() => {
                  setMacOpen(true);
                  setLinkType("trial");
                }}
              />
            )}
          </Row>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Other;
